var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "fa-caret-right";
},"3":function(container,depth0,helpers,partials,data) {
    return "fa-caret-down";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h5 id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":1,"column":10},"end":{"line":1,"column":12}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":8},"end":{"line":1,"column":14}}}) : helper)))
    + "\" class=\"buddy-list-heading no-style hidden-for-spectators\">\n    "
    + alias4((((helper = lookupProperty(helpers,"header_text") || alias1(depth0, "header_text", {"start":{"line":2,"column":6},"end":{"line":2,"column":17}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"header_text","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":19}}}) : helper)))
    + " ("
    + alias4((((helper = lookupProperty(helpers,"user_count") || alias1(depth0, "user_count", {"start":{"line":2,"column":23},"end":{"line":2,"column":33}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_count","hash":{},"data":data,"loc":{"start":{"line":2,"column":21},"end":{"line":2,"column":35}}}) : helper)))
    + ")\n</h5>\n<i class=\"buddy-list-section-toggle "
    + alias4((((helper = lookupProperty(helpers,"toggle_class") || alias1(depth0, "toggle_class", {"start":{"line":4,"column":38},"end":{"line":4,"column":50}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"toggle_class","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":52}}}) : helper)))
    + " fa fa-sm "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_collapsed"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":124}}})) != null ? stack1 : "")
    + "\" aria-hidden=\"true\"></i>\n";
},"useData":true});