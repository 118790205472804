var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hide\" id=\"stream-creation\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel creation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":39}}}))
    + "\">\n    <form id=\"stream_creation_form\">\n        <div class=\"stream-creation-simplebar-container\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <div class=\"alert stream_create_info\"></div>\n            <div id=\"stream_creating_indicator\"></div>\n            <div class=\"stream-creation-body\">\n                <div class=\"configure_channel_settings stream_creation_container\">\n                    <section id=\"create_stream_title_container\">\n                        <label for=\"create_stream_name\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":49}}}))
    + "\n                        </label>\n                        <input type=\"text\" name=\"stream_name\" id=\"create_stream_name\" class=\"settings_text_input\"\n                          placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":60}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(alias4(alias3(depth0, "max_stream_name_length", {"start":{"line":14,"column":104},"end":{"line":14,"column":126}} ), depth0))
    + "\" />\n                        <div id=\"stream_name_error\" class=\"stream_creation_error\"></div>\n                    </section>\n                    <section id=\"create_stream_description_container\">\n                        <label for=\"create_stream_description\" class=\"settings-field-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":28},"end":{"line":19,"column":56}}}))
    + "\n                            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/change-the-channel-description"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </label>\n                        <input type=\"text\" name=\"stream_description\" id=\"create_stream_description\" class=\"settings_text_input\"\n                          placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":67}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(alias4(alias3(depth0, "max_stream_description_length", {"start":{"line":23,"column":111},"end":{"line":23,"column":140}} ), depth0))
    + "\" />\n                    </section>\n                    <section id=\"make-invite-only\">\n                        <div class=\"stream-types\">\n                            "
    + ((stack1 = container.invokePartial(require("./stream_types.hbs"),depth0,{"name":"stream_types","hash":{"can_remove_subscribers_setting_widget_name":"new_stream_can_remove_subscribers_group","is_stream_edit":false,"stream_post_policy":lookupProperty(lookupProperty(lookupProperty(depth0,"stream_post_policy_values"),"everyone"),"code")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </section>\n                </div>\n                <div class=\"subscribers_container stream_creation_container\">\n                    <section class=\"stream_create_add_subscriber_container\">\n                        <label class=\"choose-subscribers-label\" for=\"people_to_add\">\n                            <h4 class=\"stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Choose subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":72},"end":{"line":37,"column":99}}}))
    + "</h4>\n                        </label>\n                        <span class=\"add_all_users_to_stream_btn_container\">\n                            <button class=\"add_all_users_to_stream small button rounded sea-green\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add all users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":99},"end":{"line":40,"column":120}}}))
    + "</button>\n                        </span>\n                        <div id=\"stream_subscription_error\" class=\"stream_creation_error\"></div>\n                        <div class=\"controls\" id=\"people_to_add\"></div>\n                    </section>\n                </div>\n            </div>\n        </div>\n        <div class=\"settings-sticky-footer\">\n            <div class=\"settings-sticky-footer-left\">\n                <button id=\"stream_creation_go_to_configure_channel_settings\" class=\"button small sea-green rounded hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Back to settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":122},"end":{"line":50,"column":147}}}))
    + "</button>\n            </div>\n            <div class=\"settings-sticky-footer-right\">\n                <button class=\"create_stream_cancel button small white rounded\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":101},"end":{"line":53,"column":116}}}))
    + "</button>\n                <button class=\"finalize_create_stream button small sea-green rounded hide\" type=\"submit\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":105},"end":{"line":54,"column":120}}}))
    + "</button>\n                <button id=\"stream_creation_go_to_subscribers\" class=\"button small sea-green rounded\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Continue to add subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":102},"end":{"line":55,"column":138}}}))
    + "</button>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});