var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-group\">\n                    <label class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":56},"end":{"line":10,"column":70}}}))
    + "</label>\n                    <div id=\"change_email_button_container\" class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_email"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":135}}})) != null ? stack1 : "")
    + "\">\n                        <button id=\"change_email_button\" type=\"button\" class=\"button rounded tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Change your email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":142},"end":{"line":12,"column":168}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_email"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":170},"end":{"line":12,"column":233}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(container.lambda(container.strict(lookupProperty(depth0,"current_user"), "delivery_email", {"start":{"line":13,"column":30},"end":{"line":13,"column":57}} ), depth0))
    + "\n                        </button>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled_setting_tooltip";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-group\">\n                    <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":26,"column":31}}})) != null ? stack1 : "")
    + "                    </p>\n                    <button id=\"demo_organization_add_email_button\" type=\"button\" class=\"button rounded sea-green\">\n                        "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Add email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":24},"end":{"line":29,"column":41}}}))
    + "\n                    </button>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            Add your email to <z-link-invite-users-help>invite other users</z-link-invite-users-help>\n                            or <z-link-convert-demo-organization-help>convert to a permanent Zulip organization</z-link-convert-demo-organization-help>.\n                            \n                            \n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/invite-new-users\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/demo-organizations#convert-a-demo-organization-to-a-permanent-organization\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p for=\"two_factor_auth\" class=\"inline-block title\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":37,"column":50}}}))
    + ": "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"page_params"),"two_fa_enabled_user"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":37,"column":52},"end":{"line":37,"column":140}}})) != null ? stack1 : "")
    + "\n                <a target=\"_blank\" rel=\"noopener noreferrer\" id=\"two_factor_auth\" href=\"/account/two_factor/\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Set up two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":117},"end":{"line":38,"column":158}}}))
    + "\">["
    + alias2(lookupProperty(helpers,"t").call(alias1,"Setup",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":161},"end":{"line":38,"column":175}}}))
    + "]</a>\n            </p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Enabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":92},"end":{"line":37,"column":108}}}));
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":116},"end":{"line":37,"column":133}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n                    <label class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":56},"end":{"line":45,"column":73}}}))
    + "</label>\n                    <div class=\"input-group\">\n                        <button id=\"change_password\" type=\"button\" class=\"button rounded\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Change your password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":111},"end":{"line":47,"column":140}}}))
    + "</button>\n                    </div>\n                </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"submit\" class=\"button rounded btn-danger deactivate_realm_button\">\n                        "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deactivate organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":24},"end":{"line":62,"column":56}}}))
    + "\n                    </button>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                    An API key can be used to programmatically access a Zulip account.\n                    Anyone with access to your API key has the ability to read your messages, send\n                    messages on your behalf, and otherwise impersonate you on Zulip, so you should\n                    guard your API key as carefully as you guard your password. <br />\n                    We recommend creating bots and using the bots' accounts and API keys to access\n                    the Zulip API, unless the task requires access to your account.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"account-settings\" class=\"settings-section\" data-name=\"account-and-privacy\">\n    <div class=\"alert\" id=\"dev-account-settings-status\"></div>\n    <div class=\"account-settings-form\">\n        <div id=\"user_details_section\">\n            <h3 class=\"inline-block account-settings-heading\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":78}}}))
    + "</h3>\n            <div class=\"alert-notification account-alert-notification\" id=\"account-settings-status\"></div>\n            <form class=\"grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + "            </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"page_params"),"two_fa_enabled"),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "")
    + "\n            <form class=\"password-change-form grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_can_change_password"),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":50,"column":23}}})) != null ? stack1 : "")
    + "            </form>\n\n            <div class=\"input-group\">\n                <div id=\"deactivate_account_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_only_organization_owner"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":75},"end":{"line":54,"column":145}}})) != null ? stack1 : "")
    + "\">\n                    <button type=\"submit\" class=\"button rounded btn-danger\" id=\"user_deactivate_account_button\"\n                      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_only_organization_owner"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":22},"end":{"line":56,"column":87}}})) != null ? stack1 : "")
    + ">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":57,"column":51}}}))
    + "\n                    </button>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"owner_is_only_user_in_organization"),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":64,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div id=\"privacy_settings_box\">\n            <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Privacy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":37},"end":{"line":69,"column":53}}}))
    + "</h3>\n            <div class=\"alert-notification privacy-setting-status\"></div>\n            <div class=\"input-group\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_private_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_private_typing_notifications"),"setting_name":"send_private_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_stream_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_stream_typing_notifications"),"setting_name":"send_stream_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/read-receipts","hide_tooltip":lookupProperty(lookupProperty(depth0,"realm"),"realm_enable_read_receipts"),"tooltip_text":lookupProperty(depth0,"send_read_receipts_tooltip"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_read_receipts"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_read_receipts"),"setting_name":"send_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"user_","help_link":"/help/status-and-availability","label_parens_text":lookupProperty(lookupProperty(depth0,"settings_label"),"presence_enabled_parens_text"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"presence_enabled"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"presence_enabled"),"setting_name":"presence_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/export-your-organization#full-export-with-member-consent","label":lookupProperty(lookupProperty(depth0,"settings_label"),"allow_private_data_export"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"allow_private_data_export"),"setting_name":"allow_private_data_export"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"input-group\">\n                <label for=\"email_address_visibility\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can access your email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":83},"end":{"line":105,"column":125}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/configure-email-visibility"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </label>\n                <div id=\"user_email_address_dropdown_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":84},"end":{"line":108,"column":149}}})) != null ? stack1 : "")
    + "\">\n                    <select name=\"email_address_visibility\" class=\"email_address_visibility prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"number\"\n                      id=\"user_email_address_visibility\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":57},"end":{"line":110,"column":117}}})) != null ? stack1 : "")
    + ">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"email_address_visibility_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"api_key_button_box\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":16},"end":{"line":118,"column":32}}}))
    + "</h3>\n\n            <div class=\"input-group\">\n                <p class=\"api-key-note\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":20},"end":{"line":129,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n                <div id=\"api_key_button_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":71},"end":{"line":131,"column":136}}})) != null ? stack1 : "")
    + "\">\n                    <button class=\"button rounded\" id=\"api_key_button\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":71},"end":{"line":132,"column":131}}})) != null ? stack1 : "")
    + ">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Manage your API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":133,"column":24},"end":{"line":133,"column":52}}}))
    + "\n                    </button>\n                </div>\n            </div>\n        </div>\n        <!-- Render /settings/api_key_modal.hbs after #api_key_button is clicked\n        to avoid password being inserted by password manager too aggressively. -->\n    </div>\n</div>\n";
},"7_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-invite-users-help"],"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":177}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-convert-demo-organization-help"],"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":248}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});