var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "zero-topic-unreads";
},"3":function(container,depth0,helpers,partials,data) {
    return "more_topic_unreads_muted_only";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"unread_mention_info\">\n                    @\n                </span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"topic-list-item show-more-topics bottom_left_row\n  "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"more_topics_unreads"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":62}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"more_topics_unread_count_muted"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":76}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"topic-box\">\n        <a class=\"sidebar-topic-action-heading\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show all topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":85}}}))
    + "</a>\n        <div class=\"topic-markers-and-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"more_topics_have_unread_mention_messages"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "            <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"more_topics_unreads"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":38},"end":{"line":12,"column":90}}})) != null ? stack1 : "")
    + "\">\n                "
    + alias2((((helper = lookupProperty(helpers,"more_topics_unreads") || container.strict(depth0, "more_topics_unreads", {"start":{"line":13,"column":18},"end":{"line":13,"column":37}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"more_topics_unreads","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":39}}}) : helper)))
    + "\n            </span>\n        </div>\n    </div>\n</li>\n";
},"useData":true});