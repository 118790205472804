var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"value_type":"number","label":lookupProperty(helpers,"t").call(alias1,"Who can mention this group?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":41}}}),"widget_name":lookupProperty(depth0,"can_mention_group_widget_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"input-group can-manage-group-container\">\n    <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can manage this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":45}}}))
    + "</label>\n    <div class=\"pill-container person_picker prop-element\" id=\"id_"
    + alias2(container.lambda(container.strict(depth0, "can_manage_group_widget_name", {"start":{"line":8,"column":68},"end":{"line":8,"column":96}} ), depth0))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":63}}}))
    + "\"></div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});