var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"edit_content message_control_button\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"reaction_button message_control_button\" data-tooltip-template-id=\"add-emoji-tooltip-template\">\n        <div class=\"emoji-message-control-button-container\">\n            <i class=\"message-controls-icon zulip-icon zulip-icon-smile\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":85},"end":{"line":8,"column":112}}}))
    + " (:)\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\"></i>\n        </div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    return "empty-star";
},"9":function(container,depth0,helpers,partials,data) {
    return "unstar";
},"11":function(container,depth0,helpers,partials,data) {
    return "star";
},"13":function(container,depth0,helpers,partials,data) {
    return "zulip-icon-star-filled";
},"15":function(container,depth0,helpers,partials,data) {
    return "zulip-icon-star";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"sent_by_me"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"sent_by_me"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"actions_hover message_control_button\" data-tooltip-template-id=\"message-actions-tooltip-template\" >\n    <i class=\"message-controls-icon message-actions-menu-button zulip-icon zulip-icon-more-vertical-spread\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Message actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":168},"end":{"line":14,"column":192}}}))
    + "\"></i>\n</div>\n\n<div class=\"star_container message_control_button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":17,"column":50},"end":{"line":17,"column":94}}})) != null ? stack1 : "")
    + "\" data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":17,"column":122},"end":{"line":17,"column":166}}})) != null ? stack1 : "")
    + "-message-tooltip-template\">\n    <i role=\"button\" tabindex=\"0\" class=\"message-controls-icon star zulip-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":18,"column":79},"end":{"line":18,"column":150}}})) != null ? stack1 : "")
    + "\"></i>\n</div>\n";
},"useData":true});