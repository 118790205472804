var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./view_code_in_playground.hbs"),depth0,{"name":"view_code_in_playground","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"code-buttons-container\">\n    <span class=\"copy_codeblock copy-btn\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":62},"end":{"line":2,"column":80}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":94},"end":{"line":2,"column":112}}}))
    + "\" role=\"button\">\n        <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n    </span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_playground_button"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});